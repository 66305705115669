import { forwardRef, useId } from 'react';

export const InputCheckbox = forwardRef(function InputCheckbox(
  { label, className, disabled, ...props },
  ref,
) {
  const id = useId();

  return (
    <div className="flex space-x-2.5">
      <input
        {...props}
        id={id}
        className={`size-5 accent-secondary ${className}`}
        ref={ref}
        type="checkbox"
        disabled={disabled}
      />
      {label && (
        <label
          className={`m-0 text-body ${props.checked ? 'font-semibold text-neutral-800' : 'font-normal text-neutral-800'}`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  );
});
