import { useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { BiCalendar, BiUser } from 'react-icons/bi';
import { MdOutlinePlace } from 'react-icons/md';
import { TbPlane } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import BreadCrumbs from '@components/breadcrumbs';
import { Button } from '@components/button';
import { CalendarType } from '@components/calendar-selector';
import { InputRadio } from '@components/input';
import {
  InputTextDropdown,
  InputTextDropdownItem,
  InputDatePicker,
  InputTextStepper,
  InputTextStepperItem,
} from '@components/input-custom';
import { AirportDto } from '@data'; // eslint-disable-line no-unused-vars
import { Container } from '@layout';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { formatToBRTz } from '@system/utils';
import { PagePath } from '../pages-config';

const TripType = {
  RoundTrip: 'round-trip',
  OneWay: 'one-way',
};
const DateFilter = {
  Interval: 'interval',
  Months: 'months',
};
const DateInputType = {
  Departure: 'departure',
  Return: 'return',
};

export default function AereoReservas() {
  const navigate = useNavigate();
  const { session } = useAuthentication();
  const pageTitle = session?.agency?.nomeFantasia ?? 'Buscar reserva';

  const departureDateInputRef = useRef(null);
  const returnDateInputRef = useRef(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const paramsObject = {};
  for (let [key, value] of searchParams.entries()) {
    paramsObject[key] = value;
  }

  const [tripType, setTripType] = useState(TripType.RoundTrip);
  const [dateFilter, setDateFilter] = useState(DateFilter.Interval);
  const [activeDateInput, setActiveDateInput] = useState(null);
  const [formFields, setFormFields] = useState({
    origin: paramsObject.origin ?? '',
    destination: paramsObject.destination ?? '',
    departureDate: paramsObject.departureDate ?? null,
    returnDate: paramsObject.returnDate ?? null,
    months: paramsObject?.months?.split(',') ?? [],
    adultPassengers: paramsObject.adultPassengers
      ? Number(paramsObject.adultPassengers)
      : 1,
    childrenPassengers: paramsObject.childrenPassengers
      ? Number(paramsObject.childrenPassengers)
      : 0,
    babyPassengers: paramsObject.babyPassengers
      ? Number(paramsObject.babyPassengers)
      : 0,
  });

  const [formErrors, setFormErrors] = useState({});
  /**
   * @type {ReturnType<typeof useState<AirportDto[]>>}
   */
  const [airports, setAirports] = useState([]);
  const [originAirport, setOriginAirport] = useState(paramsObject.origin ?? '');
  const [destinationAirport, setDestinationAirport] = useState(
    paramsObject.destination ?? '',
  );

  const isIntervalFilterActive = dateFilter === DateFilter.Interval;
  const totalPassengers =
    formFields.adultPassengers +
    formFields.childrenPassengers +
    formFields.babyPassengers;

  async function fetchAirports(e) {
    setAirports([]);
    const response = await api.get('/airports', {
      params: { search: e.target.value, limit: 5 },
    });
    setAirports(response.data);
  }

  function handleTripTypeChange(e) {
    setTripType(e.target.value);

    if (e.target.value === TripType.OneWay) handleFieldChange('returnDate', '');
  }

  function handleInputChange(e) {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  }

  function handleFieldChange(field, value) {
    setFormFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  function handleLocationFieldChange(e) {
    handleInputChange(e);
    if (e.target.name === 'origin') setOriginAirport('');
    if (e.target.name === 'destination') setDestinationAirport('');
  }

  function checkSelectedAirport(type) {
    if (type === 'origin' && !originAirport) handleFieldChange('origin', '');
    if (type === 'destination' && !destinationAirport)
      handleFieldChange('destination', '');
  }

  function normalizeString(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  function highlightSearchText(text, search) {
    const normalizedText = normalizeString(text);
    const normalizedSearch = normalizeString(search);

    const escapedSearch = normalizedSearch.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&',
    );
    const snippet = normalizedText.match(new RegExp(escapedSearch, 'i'))?.at(0);

    if (!snippet) return text;

    const index = normalizedText.toLowerCase().indexOf(snippet.toLowerCase());
    return (
      <>
        {text.slice(0, index)}
        <span className="text-secondary">
          {text.slice(index, index + snippet.length)}
        </span>
        {text.slice(index + snippet.length)}
      </>
    );
  }

  function renderAirportsDropdown(type) {
    return airports.map((airport) => {
      const search = formFields[type];
      const label = `${airport.name}, ${airport.city} (${airport.iata})`;
      const text = highlightSearchText(label, search);

      return (
        <InputTextDropdownItem
          key={airport.iata}
          onClick={() => handleAirportClick(type, airport, label)}
        >
          {text}
        </InputTextDropdownItem>
      );
    });
  }

  function handleAirportClick(type, airport, label) {
    if (type === 'origin') setOriginAirport(airport.iata);
    else setDestinationAirport(airport.iata);

    handleFieldChange(type, label);
  }

  function showDateInput(type) {
    const isTypeActive = activeDateInput === type;
    return isIntervalFilterActive || (!isIntervalFilterActive && isTypeActive);
  }

  function makeDateInputLabel(label) {
    return isIntervalFilterActive ? label : 'Por mês';
  }

  function makeDateInputValue(value) {
    const dateFormat = value ? format(value, 'dd/MM/yyyy') : '';
    const monthsFormat = formFields.months
      .map((m) => formatToBRTz(m, 'MMMM/yyyy'))
      .join(', ');
    return isIntervalFilterActive ? dateFormat : monthsFormat;
  }

  function makeDateInputPlaceholder(placeholder) {
    return isIntervalFilterActive ? placeholder : 'Insira o mês ou meses';
  }

  function handleDatePickerSwitch(calendarType, inputType) {
    if (calendarType === CalendarType.Yearly) {
      setActiveDateInput(inputType);
      setDateFilter(DateFilter.Months);
    } else {
      setActiveDateInput(null);
      setDateFilter(DateFilter.Interval);
    }
  }

  function handleDepartureDateChange(date) {
    const newReturnDate =
      date > formFields.returnDate ? null : formFields.returnDate;
    /**
     * @note This will ensure that returnDateInputRef is focused correctly
     */
    flushSync(() =>
      setFormFields((prev) => ({
        ...prev,
        departureDate: date,
        returnDate: newReturnDate,
      })),
    );

    returnDateInputRef.current?.focus();
    returnDateInputRef.current?.click();
  }

  function handleReturnDateClick(showCalendar) {
    if (formFields.departureDate) return showCalendar();
    departureDateInputRef.current?.focus();
    departureDateInputRef.current?.click();
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!validateFormFields()) return;

    const query = makeQuery();
    navigate(`${PagePath.AereoReservasResultados}?${query.toString()}`);
  }

  function validateFormFields() {
    const errors = {};
    let isValid = true;

    if (!formFields.origin) {
      errors.origin = 'Preencha o campo de origem';
      isValid = false;
    }

    if (!formFields.destination) {
      errors.destination = 'Preencha o campo de destino';
      isValid = false;
    }

    if (!isIntervalFilterActive && !formFields.months.length) {
      errors.months = 'Preencha o campo de mês';
      isValid = false;
    }

    if (isIntervalFilterActive && !formFields.departureDate) {
      errors.departureDate = 'Preencha o campo de data de ida';
      isValid = false;
    }

    if (
      tripType === TripType.RoundTrip &&
      isIntervalFilterActive &&
      !formFields.returnDate
    ) {
      errors.returnDate = 'Preencha o campo de data de volta';
      isValid = false;
    }

    if (totalPassengers < 1) {
      errors.passengers = 'Pelo menos 1 passageiro é necessário';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  }

  function makeQuery() {
    const isRoundTrip = tripType === TripType.RoundTrip;
    const departureDate = formFields.departureDate;
    const returnDate = isRoundTrip
      ? formFields.returnDate
      : formFields.departureDate;

    const query = new URLSearchParams({
      origin: originAirport,
      destination: destinationAirport,
      departureDate: departureDate && format(departureDate, 'yyyy-MM-dd'),
      returnDate: returnDate && format(returnDate, 'yyyy-MM-dd'),
      months: formFields.months.map((m) => format(m, 'yyyy-MM')),
      adultPassengers: formFields.adultPassengers,
      childrenPassengers: formFields.childrenPassengers,
      babyPassengers: formFields.babyPassengers,
    });

    if (isIntervalFilterActive) query.delete('months');
    else {
      query.delete('departureDate');
      query.delete('returnDate');
    }

    return query;
  }

  return (
    <>
      <BreadCrumbs
        paths={[
          { label: 'Aéreo', link: PagePath.Aereo },
          { label: 'Reservas', link: PagePath.AereoReservas },
        ]}
      />
      <Container className="mt-5">
        <h2 className="m-0 text-heading-2 text-primary">{pageTitle}</h2>
      </Container>
      <Container className="mt-4">
        <div className="sm:flex">
          <h2 className="mr-6 text-heading-2 text-primary">Aéreo</h2>
          <div className="flex space-x-6">
            <InputRadio
              label="Ida e volta"
              name="trip-type"
              value={TripType.RoundTrip}
              checked={tripType === TripType.RoundTrip}
              onChange={handleTripTypeChange}
            />
            <InputRadio
              label="Somente 1 trecho"
              name="trip-type"
              value={TripType.OneWay}
              checked={tripType === TripType.OneWay}
              onChange={handleTripTypeChange}
            />
          </div>
        </div>
        <div className="mt-6">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-2.5 lg:flex-row lg:space-x-2.5 lg:space-y-0">
              <InputTextDropdown
                name="origin"
                label="Origem"
                autoComplete="off"
                placeholder="Insira a origem"
                icon={MdOutlinePlace}
                dropdownTitle="Aeroportos"
                dropdownIcon={TbPlane}
                value={formFields.origin}
                noValidate
                error={formErrors.origin}
                onChange={handleLocationFieldChange}
                onDropdownAppears={fetchAirports}
                onDropdownDisappears={() => checkSelectedAirport('origin')}
              >
                {renderAirportsDropdown('origin')}
              </InputTextDropdown>
              <InputTextDropdown
                name="destination"
                label="Destino"
                autoComplete="off"
                placeholder="Insira o destino"
                icon={MdOutlinePlace}
                dropdownTitle="Aeroportos"
                dropdownIcon={TbPlane}
                value={formFields.destination}
                noValidate
                error={formErrors.destination}
                onChange={handleLocationFieldChange}
                onDropdownAppears={fetchAirports}
                onDropdownDisappears={() => checkSelectedAirport('destination')}
              >
                {renderAirportsDropdown('destination')}
              </InputTextDropdown>
            </div>
            <div className="flex flex-col space-y-2.5 lg:mt-4 lg:flex-row lg:items-start lg:space-x-2.5 lg:space-y-0">
              {showDateInput(DateInputType.Departure) && (
                <InputDatePicker
                  name="departureDate"
                  label={makeDateInputLabel('Data da ida')}
                  autoComplete="off"
                  placeholder={makeDateInputPlaceholder('Insira a data da ida')}
                  icon={BiCalendar}
                  value={makeDateInputValue(formFields.departureDate)}
                  noValidate
                  error={formErrors.departureDate || formErrors.months}
                  intervalStart={formFields.departureDate}
                  changeOnly="start"
                  multiCalendar
                  onTypeSwitch={(type) =>
                    handleDatePickerSwitch(type, DateInputType.Departure)
                  }
                  selectedMonths={formFields.months}
                  onDateChange={handleDepartureDateChange}
                  onMonthsChange={(months) =>
                    handleFieldChange('months', months)
                  }
                  ref={departureDateInputRef}
                />
              )}
              {showDateInput(DateInputType.Return) && (
                <InputDatePicker
                  name="returnDate"
                  label={makeDateInputLabel('Data da volta')}
                  autoComplete="off"
                  placeholder={makeDateInputPlaceholder(
                    'Insira a data da volta',
                  )}
                  icon={BiCalendar}
                  value={makeDateInputValue(formFields.returnDate)}
                  noValidate
                  disabled={tripType === TripType.OneWay}
                  error={formErrors.returnDate || formErrors.months}
                  intervalStart={formFields.departureDate}
                  intervalEnd={formFields.returnDate}
                  changeOnly="end"
                  multiCalendar
                  onTypeSwitch={(type) =>
                    handleDatePickerSwitch(type, DateInputType.Return)
                  }
                  startCalendar={formFields.departureDate}
                  selectedMonths={formFields.months}
                  onClick={handleReturnDateClick}
                  onDateChange={(date) => handleFieldChange('returnDate', date)}
                  onMonthsChange={(months) =>
                    handleFieldChange('months', months)
                  }
                  ref={returnDateInputRef}
                />
              )}
              <InputTextStepper
                name="passengers"
                label="Passageiros"
                autoComplete="off"
                placeholder="Insira o n. de passageiros"
                icon={BiUser}
                dropdownTitle="Passageiros"
                value={totalPassengers}
                noValidate
                error={formErrors.passengers}
              >
                <InputTextStepperItem
                  name="adultPassengers"
                  label="Adultos"
                  autoComplete="off"
                  tip="12+"
                  value={formFields.adultPassengers}
                  onChange={handleInputChange}
                  max={10}
                />
                <InputTextStepperItem
                  name="childrenPassengers"
                  label="Crianças"
                  autoComplete="off"
                  tip="2 a 11 anos"
                  value={formFields.childrenPassengers}
                  onChange={handleInputChange}
                  max={10}
                />
                <InputTextStepperItem
                  name="babyPassengers"
                  label="Bebês"
                  autoComplete="off"
                  tip="0 a 23 meses"
                  value={formFields.babyPassengers}
                  onChange={handleInputChange}
                  max={10}
                />
              </InputTextStepper>
              <Button
                type="submit"
                className="!mt-7 h-12 w-full lg:basis-1/2"
                label="Buscar"
              />
            </div>
          </form>
        </div>
      </Container>
    </>
  );
}
